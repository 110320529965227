import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hello, world!",
  "date": "2022-07-25",
  "author": "Andy B"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Welcome to the new website for the Bishops Cleeve CoderDojo!`}</p>
    <p>{`We will be restarting sessions in September, so watch this space for news and information on how to book.  We are very much looking forward to seeing you all back in the library, and getting stuck in to some fun new projects.`}</p>
    <p>{`By the way, do you recognise the title of this post?  When people are learning a new programming language, it's a tradition to start with a simple program that prints out "Hello, world!".`}</p>
    <p>{`Here's an example in the Python language:`}</p>
    <pre><code parentName="pre" {...{}}>{`print("Hello, world!")
`}</code></pre>
    <p>{`What do you like to make when you first try a new language?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      